/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { Button, Go, LineSwipe } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

const ColumnButtonSection = ({
  data: { backgroundColor, fontColor, headingFontColor, forms }
}) => {
  console.log(forms);

  //

  return (
    <div
      css={[
        css`
          background: ${backgroundColor?.hex};
        `,
        tw`md:pt-20 lg:pt-24`
      ]}
    >
      <Grid>
        {forms.map((form) => (
          <article
            id={`form-${form.heading}`}
            css={[
              tw`col-span-12 md:col-span-6 relative mb-6 md:mb-24 md:pr-36 text-black`
            ]}
          >
            <header
              css={[
                tw`w-full relative flex items-center mt-16 md:mt-0 mb-12 lg:mb-16`
              ]}
            >
              <T.Head
                _css={[
                  css`
                    color: ${headingFontColor?.hex || getColor(`blue-deep`)};
                  `
                ]}
                font="4"
              >
                {form.heading}
              </T.Head>

              <div css={[tw`w-32 relative block ml-5`]}>
                <LineSwipe
                  color={headingFontColor?.hex === `#ffffff` && `#ffffff`}
                  delay={500}
                  duration={2000}
                />
              </div>
            </header>

            <T.Body
              _css={[
                css`
                  white-space: pre-wrap;
                  color: ${fontColor?.hex || getColor(`black`)};
                `,
                tw`mb-12 md:mb-20`
              ]}
              font="1"
            >
              {form.body}
            </T.Body>

            {forms?.length > 1 && form?.buttonLink?.url && (
              <div css={[tw`w-full relative mb-8`]}>
                <Go
                  _css={[tw`w-full md:w-auto inline-block`]}
                  to={form.buttonLink.url}
                >
                  <Button
                    _css={[
                      css`
                        width: 100%;
                        color: ${getColor(`black`)};

                        ${MEDIA_QUERIES.desktop} {
                          width: auto;
                          min-width: 19rem;
                        }
                      `,
                      tw`h-14 relative block border`
                    ]}
                    text={form.buttonLink.text}
                  />
                </Go>
              </div>
            )}

            {form?.downloadButtonText && form?.download?.asset?.url && (
              <div css={[tw`w-full relative mb-8`]}>
                <a
                  href={form.download.asset.url}
                  css={[tw`w-full md:w-auto inline-block`]}
                  download
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button
                    _css={[
                      css`
                        width: 100%;
                        color: ${getColor(`black`)};

                        ${MEDIA_QUERIES.desktop} {
                          width: auto;
                          min-width: 19rem;
                        }
                      `,
                      tw`h-14 relative block border`
                    ]}
                    text={form.downloadButtonText}
                  />
                </a>
              </div>
            )}
          </article>
        ))}

        {forms?.length === 1 && (
          <article
            css={[
              tw`col-span-12 md:col-span-6 relative md:pt-24 pb-12 md:pb-12 text-black`
            ]}
          >
            {forms[0]?.buttonLink && (
              <div css={[tw`w-full relative mt-2`]}>
                <Go
                  _css={[tw`w-full md:w-auto inline-block`]}
                  to={forms[0].buttonLink.url}
                >
                  <Button
                    _css={[
                      css`
                        width: 100%;
                        color: ${getColor(`black`)};

                        ${MEDIA_QUERIES.desktop} {
                          width: auto;
                          min-width: 19rem;
                        }
                      `,
                      tw`h-14 relative block border`
                    ]}
                    text={forms[0].buttonLink.text}
                  />
                </Go>
              </div>
            )}

            {forms[0]?.downloadButtonText && forms[0]?.download?.asset?.url && (
              <div css={[tw`w-full relative mb-8`]}>
                <a
                  href={forms[0].download.asset.url}
                  css={[tw`w-full md:w-auto inline-block`]}
                  download
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button
                    _css={[
                      css`
                        width: 100%;
                        color: ${getColor(`black`)};

                        ${MEDIA_QUERIES.desktop} {
                          width: auto;
                          min-width: 19rem;
                        }
                      `,
                      tw`h-14 relative block border`
                    ]}
                    text={forms[0].downloadButtonText}
                  />
                </a>
              </div>
            )}
          </article>
        )}
      </Grid>
    </div>
  );
};

export default ColumnButtonSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ColumnButtonSectionFragment on SanityColumnButtonSection {
    _type

    backgroundColor {
      hex
      title
    }
    headingFontColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    forms {
      heading
      body

      download {
        asset {
          url
        }
      }
      downloadButtonText

      buttonLink {
        text
        url
      }
    }
  }
`;
